import React, { Component } from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import Fab from '@material-ui/core/Fab';
import "./css/Main.css"


const Hello = () => {
    return (
        <>
      <div className='div1'>
          <div className='div2'>
            <p className='par'> <center>Drop by to say Hi</center></p>
            <table>
              <tbody>
                <tr >
                  <th><button style={{backgroundColor:"#040303"}} onClick={(e) => {e.preventDefault()
              window.location.href='https://github.com/MJNakum/'} }><Fab color="success" aria-label="edit">
                      <GitHubIcon />
                    </Fab></button></th>
                  <th><button style={{backgroundColor:"#040303"}} onClick={(e) => {e.preventDefault()
              window.location.href='https://www.instagram.com/meet_nakum_3131'} }><Fab color="success" aria-label="edit">
                      <InstagramIcon />
                    </Fab></button></th>
                  <th><button style={{backgroundColor:"#040303"}} onClick={(e) => {e.preventDefault()
              window.location.href='https://www.linkedin.com/in/meet-nakum-4323791ab/'} }><Fab color="success" aria-label="edit">
                      <LinkedinIcon />
                    </Fab></button></th>
                  <th><button style={{backgroundColor:"#040303"}} onClick={(e) => {e.preventDefault()
              window.location.href='https://mail.google.com/mail/#inbox?compose=CllgCJNwfTnfCWlPGjwHGnNGdBwxxpXxLccbWQHWvqmVqTnjllVXzvhvjbWgssVswJcdNxKkcSB'} }><Fab color="success" aria-label="edit">
                      <MailIcon />
                    </Fab></button></th>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
        <div>
        </div>
                  </>
    )
}

export default Hello;