import React, { Component } from 'react'
import "./css/Main.css"


const Hello = () => {
    return (
        <>
        <div className='div1'>
          <div className='div2'> 
            <p className='par'> <center>About Me</center></p>
            <table>
              <tbody>
                <tr className='abt'>
                  <th style={{display : "flex", justifyContent:"center"}}>
                    <img className='images' src={require("./resources/self.jpeg")} />
                  </th>
                  <th ><h1>Hey... I am Meet Nakum. I am web-Developer and Competative Programmer. I have good designing and great Problem solving skills. I do things which makes me feel great about myself and help me grow in the world of technology.  </h1></th>
                </tr>       
              </tbody>
            </table>
          </div>
        </div>
        </>
    )
}

export default Hello;