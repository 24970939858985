import "./App.css";
import Laptop from "./components/Laptop";
import Main from "./components/Main";
import { ReactNavbar } from "react-responsive-animate-navbar";

function App() {
  return (
    <div className="App">
      <ReactNavbar
        color="rgb(25, 25, 25)"
        logo="https://img.icons8.com/ios-filled/344/mario-8-bit.png"
        menu={[
          { name: "HOME", to: "/", component: Main },
          { name: "ABOUT", to: "/", component: Main },
          { name: "SKILLS", to: "/", component: Main },
          { name: "PROJECTS", to: "/", component: Main },
        ]}
        social={[
          {
            name: "Linkedin",
            url: "https://www.linkedin.com/in/meet-nakum-4323791ab/",
            icon: ["fab", "linkedin-in"],
          },
          {
            name: "Github",
            url: "https://github.com/MJNakum",
            icon: ["fab", "github"],
          },
          {
            name: "Instagram",
            url: "https://www.instagram.com/meet_nakum_3131",
            icon: ["fab", "instagram"],
          },
          {
            name: "Twitter",
            url: "https://twitter.com/MeetNakum31",
            icon: ["fab", "twitter"],
          },
        ]}
      />
      <Laptop />
      <Main />
    </div>
  );
}

export default App;
