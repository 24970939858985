import React, { Component } from 'react'
import "./css/Main.css"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Hello = () => {
    const card = (
  <React.Fragment>
    <table style={{backgroundColor : "#040303"}}>
      <tbody>
        <tr >
          <th>
            <CardContent className='card' onClick={(e) => {e.preventDefault()
              window.location.href='https://github.com/MJNakum/tictactoe'} } >
              <div className='par2'>
                tictactoe
              </div>
            </CardContent>
          </th>
          <th>
            <CardContent className='card'>
              <div className='par2  '>
                QuizApp
              </div>
            </CardContent>
          </th>
          <th>
            <CardContent className='card'>
              <div className='par2  '>
                Banking System
              </div>
            </CardContent>
          </th>
        </tr>
      </tbody>
    </table>
  </React.Fragment>
);
    return (
        <>
        <div className='div1'>
          <div className='div2'>
            <p className='par'> <center>Projects</center></p>
            <div style={{width : "800px"}}>
              <Card>
                {card}
              </Card>
            </div>
          </div>
        </div>
        <div>
        </div>
        </>
    )
}

export default Hello;