import React, { Component } from 'react'
import "./css/Main.css"


const Hello = () => {
    return (
        <>
        <div className='div1'>
          <div className='div2'>
            <p className='par'> <center>Skills</center></p>
            <div style={{width : "800px"}}>
              <table>
              <tbody>
                <tr className='str'>
                  <th>
                    <img className='image2' src={require("./resources/c++.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/python.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/html.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/javascript.png")} />
                  </th>
                </tr>  
                <tr className='str'>
                  <th>
                    <img className='image2' src={require("./resources/css.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/react.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/django.png")} />
                  </th>
                  <th>
                    <img className='image2' src={require("./resources/firebase.png")} />
                  </th>
                  
                </tr>     
              </tbody>
            </table>
            </div>
          </div>
        </div>
        </>
    )
}

export default Hello;