import React, { Component } from 'react'
import "./css/Main.css"
import { useMediaQuery } from 'react-responsive';
import Hello from "./Hello"
import Aboutme from "./Aboutme"
import Projects from "./Projects"
import Skills from "./Skills"



const Main = () => {

  

    return (
      <>
     
       
        
        <Aboutme />
        <Skills />
        <Projects />
        <Hello />
        <center className="par3">

        Created and Managed by Meet Nakum.
        </center>
      </>
    )
}

export default Main;