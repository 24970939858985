import React, { Component } from "react";
import "./css/Laptop.scss";
import Typical from "react-typical";
import { display } from "@mui/system";

const Laptop = () => {
  return (
    <>
    <div style={{marginTop : "75px"}}>
      <div className="res">
      <div className="container">
        <div className="laptop">
          <p>
            [Version 1.01.02] <br></br>(c) MJN. All rights reserved.
          </p>
          <p>{"C:/Users >> "}Welcome !</p>
          <p>{"C:/Users >> "} </p>
          <p>{"C:/Users >> I am Meet Nakum."} </p>
          <Typical
            steps={[
              2000,
              "C:/Users >> ",
              1000,
              "C:/Users >> I",
              500,
              "C:/Users >> I am a ",
              500,
              "C:/Users >> I am a Web",
              500,
              "C:/Users >> I am a Web-Designer.",
              500,
              "C:/Users >> I am a Web",
              500,
              "C:/Users >> I am a Web-Developer.",
              500,
              "C:/Users >> I am a ",
              500,
              "C:/Users >> I am a Competative",
              500,
              "C:/Users >> I am a Competative Programmer.",
              500,
            ]}
            loop={Infinity}
            wrapper="p"
            />
          <div className="base"></div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Laptop;
